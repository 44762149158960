import dynamic from 'next/dynamic';

const PageContainer = dynamic(() => import('../components/PageContainer/PageContainer'));
const Error404 = dynamic(() => import('../components/Errors/Error404'));

const Custom404 = () => {
  return (
    <PageContainer>
      <Error404 />
    </PageContainer>
  );
};

export default Custom404;
